<template>
  <div id="inspire">
    <v-card class="mx-auto" style="max-width: 600px">
      <v-card-title
        class="headline grey lighten-3 text-center pb-3 pt-3"
        style="display: flex; justify-content: center"
      >
        Авторизация
      </v-card-title>
      <v-form class="pa-4 pt-6" @submit="login()">
        <v-container>
          <v-text-field
            color="orange"
            filled
            v-model="form.email"
            label="e-mail: "
            :rules="rules.email"
          />
          <v-text-field
            color="orange"
            filled
            v-model="form.password"
            label="Пароль: "
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
          />
        </v-container>
      </v-form>
      <v-divider />
      <v-card-actions style="display: flex; justify-content: center">
        <v-btn
          type="submit"
          class="white--text"
          color="orange"
          @click="login()"
        >
          Войти
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { FOOD_TICKET_MANAGER } from "@/shared/config";

import store from "../../store/auth/index";
import toast from "../../plugins/toast";
import user from "../../store/auth";

export default {
  name: "Login",
  data() {
    return {
      show1: false,
      form: {
        email: "",
        password: ""
      },
      rules: {
        email: [v => !!(v || "").match(/@/) || "Некорректный E-mail"],
        required: [v => !!v || "Обязательное поле для заполнения "]
      }
    };
  },
  mounted() {
    const self = this;
    window.addEventListener("keyup", function(event) {
      if (event.keyCode === 13) {
        self.login();
      }
    });
  },
  methods: {
    login() {
      store
        .dispatch("login", this.form)
        .then(({ data }) => {
          if (data?.user?.user_role === FOOD_TICKET_MANAGER) {
            this.$router.push("/empty");
          } else {
            this.$router.push("/");
          }
        })
        .catch(err => {
          console.log(err);
          toast.error("Пользователь не найден");
        });
    }
  }
};
</script>

<style scoped></style>
